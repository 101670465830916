.App {
  text-align: center;
  margin: 10px;
  border: solid 1px #000;
  border-bottom: 0;
  min-height: 50px; /* min-height instead of height */
  min-width: 0;
  display: flex;
  background-color: #E8EcF4;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/* Custom player style */
midi-player {
  display: block;
  width: 100%;
  margin: 4px;
  margin-bottom: 0;
}
midi-player::part(control-panel) {
  background: rgba(174, 179, 182, 0.288);
  border: 2px solid #000;
  border-radius: 10px 10px 0 0;
}
midi-player::part(play-button) {
  color: #353;
  border: 2px solid currentColor;
  background-color: rgb(141, 182, 236);
  border-radius: 20px;
  transition: all 0.2s;
  content: 'hello';
}
midi-player::part(play-button):hover {
  color: rgb(231, 15, 26);
  background-color: rgb(8, 241, 222);
  border-radius: 10px;
}
midi-player::part(time) {
  font-family: monospace;
}

/* Custom visualizer style */
midi-visualizer .piano-roll-visualizer {
  background: #ffd;
  border: 2px solid rgb(26, 3, 3);
  border-top: none;
  border-radius: 0 0 10px 10px;
  margin: 4px;
  margin-top: 0;
  overflow: auto;
  width: 100%;
}
midi-visualizer svg rect.note {
  opacity: 0.6;
  stroke-width: 2;
}
midi-visualizer svg rect.note[data-instrument="0"]{
  fill: #e22;
  stroke: #500;
}
midi-visualizer svg rect.note[data-instrument="2"]{
  fill: #2ee;
  stroke: #055;
}
midi-visualizer svg rect.note[data-is-drum="true"]{
  fill: #888;
  stroke: #888;
}
midi-visualizer svg rect.note.active {
  opacity: 0.9;
  stroke: #000;
}  
